<!--PMS项目管理系统-->
<template>
  <div class="pms-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="PMS项目管理系统" @btnClick="bannerClick"
                  imgStyle="width:411px;height:368px" :imgSrc="`${require('@/assets/img/solution/PMS项目管理系统.png')}`"
                  contentStyle="width:90%"/>
    <!--项目管理面临的痛点-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">项目管理面临的痛点</p>
        <div class="module-col">
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="col-content-1">
                <p class="col-title">依靠人员经验</p>
                <p class="col-content" style="margin-block-end: 60px">传统项目管理通过人员经验进行管理，项目人员的专业水平并不统一，缺少标准化项目流程，项目一多就容易造成项目失控。</p>
                <div class="col-img"></div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="col-content-2">
                <p class="col-title">项目跟踪难</p>
                <p class="col-content">项目的过程进度分散在每个协作人员，缺少业务协同，数据及反馈依靠一线人员填报，很多人员不愿意使用，造成数据收集困难。</p>
              </div>
              <div class="col-content-2" style="margin-bottom: 0px">
                <p class="col-title">成本管控难</p>
                <p class="col-content">项目中涉及人天工时、费用、采购等成本支出，支出时缺少管控和统计，无法实时反映项目成本，造成事后项目成本超支。</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="col-content-2">
                <p class="col-title">项目协同难</p>
                <p class="col-content">一个项目需要多个部门、团队、人员协作沟通，团队之间采用不同的沟通平台，项目文档分散在不同区域，协作效率低。</p>
              </div>
              <div class="col-content-2" style="margin-bottom: 0px">
                <p class="col-title">领导决策难</p>
                <p class="col-content">由于数据的实时性不够，产生的风险也无法及时预警，领导难以及时获取项目相关数据及报表，造成监管难决策难。</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="col-content-2">
                <p class="col-title">知识难沉淀</p>
                <p class="col-content">项目知识经验难以沉淀复用，项目过程中的知识文档缺乏统一管理，版本混乱，项目档案易丢失损毁，造成后期项目知识利用价值低</p>
              </div>
              <div class="col-content-2" style="margin-bottom: 0px">
                <p class="col-title">需求调整难</p>
                <p class="col-content">不同组织的项目管理方法、标准、内容等各不相同，需要调整时依赖项目管理系统的供应商，造成调整响应慢、周期长。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!--PMS系统核心亮点-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">PMS系统核心亮点</p>
        <div class="module-col">
          <div class="module-left">
            <div class="module-li li-1">
              <p class="title">任务协同</p>
              <p class="content">让每一个项目人员任务清晰，协作更加方便简单，多方消息可以实时互通，所有沟通协作围绕任务展开</p>
            </div>
            <div class="module-li li-2">
              <p class="title">信息集合</p>
              <p class="content">保证项目前期、中期和后期所有资料和信息的统一归集和方便调阅，所有与项目相关的数据都围绕项目卡片进行归集</p>
            </div>
            <div class="module-li li-3">
              <p class="title">成本核算</p>
              <p class="content">能方便的进行工时统计和核算，所有成本按项目归集，实时反映项目成本收益，结合项目预算实现项目成本的实时管控</p>
            </div>
            <div class="module-li li-4">
              <p class="title">保障履约</p>
              <p class="content">围绕合同条款及收支建立计划和任务，一旦进度达成及时进行提醒，保证合同目标按时达成</p>
            </div>
          </div>
          <div class="center-module">
            <div class="module-center"/>
          </div>
          <div class="module-right">
            <div class="module-li li-1">
              <p class="title">内外协同</p>
              <p class="content">内外部用户通过一个平台即可掌握项目情况，能及时进行交流，实现内外部用户的沟通和事项处理，解决项目中的信息对称问题</p>
            </div>
            <div class="module-li li-2">
              <p class="title">智能化</p>
              <p class="content">提升项目效率，通过RPA、OCR等技术，自动完成项目填报和数据采集；通过智能语音技术进行项目数据查询和任务安排等</p>
            </div>
            <div class="module-li li-3">
              <p class="title">数字化</p>
              <p class="content">实现项目管理的全程数字化，通过电子签技术确保各类单据线上签署，身份认证技术确保信息访问安全有效</p>
            </div>
            <div class="module-li li-4">
              <p class="title">业务协同</p>
              <p class="content">实现以项目为中心，所有业务协同运作，通过客户管理、文档管理、客服管理等支撑，让整个组织以项目为中心进行运作</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--合同管理产品亮点-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title" style="color: #FFFFFF">PMS全生命周期管控</p>
        <div class="module-img"/>
      </div>
    </div>
    <!--PLM系统能解决的核心痛点-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云PLM的优势</p>
        <div class="module-col">
          <el-row :gutter="15">
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-1"/>
                  <p class="title">知进度</p>
                  <p class="content">以项目生命周期为基础，围绕执行中的问题、变化、时间等因素，以流程穿透项目过程，岗位配合度、各环节执行效率，直观展示、信息透明。</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-2"/>
                  <p class="title">控成本</p>
                  <p class="content">以项目为核心，从多个维度将各类成本汇总，让项目经理知道哪一块成本高、费用投放比例等。通过对项目采购及人员成本的动态统计分析，对项目预算进行实时监控。</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-3"/>
                  <p class="title">防风险</p>
                  <p class="content">实现项目风险智能识别，主动预警，实时监控；建立项目风险库，对项目验收超期、项目需求变更、项目付款延期等风险进行提醒。处置、整改过程全周期记录，避免损失。</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="advantages-col">
                <div class="tabs-col">
                  <div class="product product-4"/>
                  <p class="title">提质量</p>
                  <p class="content">围绕项目进度计划，将项目分解为任务，任务的负责人、时间安排、进展情况台帐一一记录。项目、任务都有时间节点，项目人员通过流程汇报进度，反馈问题，确保项目质量安全，防止超期。</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'PMS项目管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>

<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";
export default {
name: "pms",
  components: {NewCloudeFooter, NewCloudeHeader,headerBanner,applyTrial},
  data(){
    return{
      bannerContent:'PMS覆盖不同项目类型全生命周期管理，通过对业务流程及业务对象建模，通过对需求分析、项目立项管理、项目变更管理等多种企业业务流程的数字化建模，对项目过程中的项目、问题、风险等业务对象的数字化建模，实现项目全生命周期的数字化管理，为高层精准决策、中层监控全面、基层高效执行、及经验及时沉淀与分享提供保障。',
    }
  },
  methods:{
    bannerClick(){
      this.$refs.dialog.show = true;
    }
  }
}
</script>

<style scoped lang="scss">
.pms-container{
  background: #FFFFFF;
  .module-1 {
    background: #FFFFFF;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        .col-content-1{
          background: #E5E9FF;
          padding: 30px 20px 0px;
          height: 358px;
          .col-img{
            width: 262px;
            height: 155px;
            background: url("~@/assets/img/solution/2023728.png");
            background-size: 100% 100%;
          }
        }
        .col-content-2{
          background: #F7F8FF;
          padding: 30px 20px;
          margin-bottom: 10px;
        }
        .col-title{
          border-left: 4px solid #1935DE;
          padding-left: 10px;
          font-size: 18px;
          line-height: 18px;
          color: #333333;
          font-family: 'SourceHanSansCN';
          margin-block-start: 0px;
          margin-block-end: 15px;
        }
        .col-content{
          font-size: 16px;
          color: #333333;
          font-family: 'SourceHanSansCN';
          margin-block-start: 0px;
          margin-block-end: 0px;
        }
      }
    }
  }
  .module-2{
    background: #F7F8FE;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        display: flex;
        justify-content: space-between;
        .center-module{
          width: 530px;
          height: 509px;
          .module-center{
            width: 530px;
            height: 509px;
            background: url("~@/assets/img/solution/PMS系统核心亮点.png");
            background-size: 100% 100%;
          }
        }
        .module-left{
          width: 350px;
          margin-top: -10px;
          .module-li {
            text-align: end;

            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }

            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
            }
          }
          .li-1{
            width: 389px;
          }
          .li-2 {
            margin-top: 62px;
            width: 311px;
          }

          .li-3 {
            width: 311px;
            margin-top: 77px;
          }
          .li-4{
            width: 389px;
            margin-top: 45px;
          }
        }
        .module-right {
          width: 350px;
          margin-top: -10px;

          .module-li {
            text-align: left;

            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }

            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
            }
          }

          .li-1{
            width: 385px;
            margin-left: -60px;
          }
          .li-2 {
            margin-top: 40px;
            margin-left: 40px;
            width: 290px;
          }

          .li-3 {
            width: 290px;
            margin-top: 77px;
            margin-left: 40px;
          }
          .li-4{
            width: 385px;
            margin-top: 35px;
            margin-left: -60px;
          }
        }
      }
    }
  }
  .module-3 {
    background: url("~@/assets/img/solution/组1540.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 550px;
    padding-bottom: 40px;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-img {
        width: 1200px;
        height: 371px;
        background: url("~@/assets/img/solution/PMS全生命周期管控.png") no-repeat;
        background-size: 100% 100%;
        float: right;
      }
    }
  }
  .module-4 {
    background: #FFFFFF;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module-col {
        .advantages-col {
          border: 1px solid #DDDDDD;
          background: #FFFFFF;
          height: 340px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;

          .product {
            height: 90px;
            width: 104px;
            margin: auto;
          }

          .product-1 {
            background: url("~@/assets/img/solution/ys01.png");
            background-size: 100% 100%;
          }

          .product-2 {
            background: url("~@/assets/img/solution/ys02.png");
            background-size: 100% 100%;
          }

          .product-3 {
            background: url("~@/assets/img/solution/ys03.png");
            background-size: 100% 100%;
          }

          .product-4 {
            background: url("~@/assets/img/solution/ys04.png");
            background-size: 100% 100%;
          }

          .title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 10px;
          }
          .content{
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 10px;
            width: 250px;
          }
        }
      }
    }
  }
  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
